<template>
  <div class="login-container">
    <div class="centercontain">
      <div class="left">
        <el-image class="backimage" :src="icon2" />
        <div class="logocintain">{{ loginText }}</div>
      </div>

      <div class="right">
        <div class="logoitem">
          <el-image class="logo" :src="icon1" />
          <!-- <el-image class="logo" :src="icon1_1" v-else /> -->
        </div>
        <!-- <div class="logoitem logoitem2">
          <el-image class="logo" :src="icon7" />
        </div>-->
        <el-form
          ref="loginForm"
          :rules="forgetRules"
          :model="loginForm"
          class="elform"
        >
          <!-- <el-form-item label class="elitem" prop="mobile" style="margin-top: 40px">
            <div class="inputcontain">
              <el-input
                placeholder="请输入手机号"
                v-model="loginForm.mobile"
                type="text"
                class="elinput"
                maxlength="11"
              >
                <template slot="prepend">+86</template>
              </el-input>
            </div>
          </el-form-item>-->
          <!-- <el-form-item label class="elitem" prop="sms_code">
            <div class="inputcontain_1">
              <el-input
                placeholder="请输入验证码"
                v-model="loginForm.sms_code"
                type="text"
                class="elinput"
              >
                <template slot="append" v-if="show">
                  <div @click="getCode()">
                    <span>获取验证码</span>
                  </div>
                </template>
                <template slot="append" v-if="!show">
                  <div @click="getCode" class="getCode">
                    <span>{{ count + "s" }}后重发</span>
                  </div>
                </template>
              </el-input>
            </div>
          </el-form-item>-->
          <template v-if="flag === 'register'">
            <el-form-item
              label
              class="elitem"
              prop="email"
              style="margin-top: 30px"
            >
              <div class="inputcontain">
                <el-input
                  v-model="loginForm.email"
                  placeholder="请输入邮箱"
                  type="text"
                  class="elinput"
                />
              </div>
            </el-form-item>
          </template>
          <!--重置密码-->
          <template v-else>
            <el-form-item label class="elitem" style="margin-top: 30px">
              <div class="inputcontain">
                <el-input
                  v-model="baseVal"
                  placeholder="请输入手机号或邮箱"
                  type="text"
                  class="elinput"
                  @change="inputType()"
                />
              </div>
            </el-form-item>
          </template>
          <el-form-item label class="elitem" prop="code">
            <div class="inputcontain_1">
              <el-input
                placeholder="请输入验证码"
                v-model="loginForm.code"
                type="text"
                class="elinput"
              >
                <template slot="append" v-if="show">
                  <el-button
                    type="text"
                    class="code_text"
                    @click="getCode()"
                    :loading="get_code"
                  >
                    获取验证码
                  </el-button>
                </template>
                <template slot="append" v-if="!show">
                  <div class="getCode">
                    <span>{{ count + "s" }}后重发</span>
                  </div>
                </template>
              </el-input>
            </div>
          </el-form-item>
          <template v-if="flag === 'register'">
            <el-form-item label class="elitem">
              <div class="inputcontain itemSelect">
                <el-select
                  v-model="loginForm.school_id"
                  placeholder="请选择学校"
                  v-selectScroll="handleSchoolScroll"
                  clearable
                  filterable
                  :filter-method="selectChange"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in schooloption"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </template>
          <el-form-item label class="elitem elitem2" prop="password">
            <div class="inputcontain">
              <el-image class="user" :src="icon3" />
              <el-input
                class="elinput"
                v-model="loginForm.password"
                placeholder="请输入密码"
                autocomplete="off"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label class="elitem elitem2" prop="confirm_password">
            <div class="inputcontain">
              <el-image class="user" :src="icon3" />
              <el-input
                class="elinput"
                v-model="loginForm.confirm_password"
                placeholder="确认密码"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>

        <div class="logoitem logbt">
          <template v-if="flag === 'register'">
            <el-button
              class="bt"
              :loading="registerLoad"
              @click="registerUaserName('loginForm')"
            >
              注册
            </el-button>
          </template>
          <template v-else>
            <el-button
              class="bt"
              :loading="submitLoad"
              @click="submit('loginForm')"
            >
              提交
            </el-button>
          </template>
          <div class="login_name" @click="loginName">
            <span>使用已有账号登录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getschoollist } from "@/api/school";
import {
  sendsmscode,
  registerUaserName,
  sendemailcode,
  forgetpw,
} from "@/api/user";
import { getschoolinfo } from "@/api/school.js";
import md5 from "js-md5";
import { validatePhone } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  name: "Register",
  components: {},
  data() {
    const pwdCheck = (rule, value, callback) => {
      if (value != this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const codeCheck = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入正确的验证码!"));
      } else {
        callback();
      }
    };
    // const validateUsername = (rule, value, callback) => {
    //   if (!validUsername(value)) {
    //     callback(new Error("请输入正确手机号"));
    //   } else {
    //     callback();
    //   }
    // };
    // const validatePassword = (rule, value, callback) => {
    //   if (value.length < 6) {
    //     callback(new Error("请输入正确的登录密码"));
    //   } else {
    //     callback();
    //   }
    // };
    const validate_Phone = (rule, value, callback) => {
      if (!validatePhone(value)) {
        callback(new Error("请输入正确的手机号码!"));
      } else {
        callback();
      }
    };
    return {
      btid: -1,
      dialogVisible: false,
      identifiers: "",
      icon1: "",
      icon1_1: require("@/assets/shifan02.png"),
      icon2: require("@/assets/登录/ujoywzbg.png"),
      icon3: require("@/assets/登录/ujoypwd.png"),
      icon4: require("@/assets/登录/bg1.jpg"),
      icon5: require("@/assets/登录/用户.png"),
      icon6: require("@/assets/登录/text.png"),
      icon7: require("@/assets/登录/组 1.png"),
      checked: "",
      show: true,
      count: "",
      schoolparames: {
        keyword: "",
        page: 1,
        per_page: 10,
      },
      schooloption: [],
      schoolMoreItems: true, // 是否还有更多选项
      loginForm: {
        // mobile: "", // 手机号格式
        password: "",
        confirm_password: "",
        // sms_code: "",
        email: "",
        code: "",
        school_id: "",
      },
      forgetRules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 11,
            message: "手机号码长度不能超过11个字符",
            trigger: "blur",
          },
          {
            required: true,
            validator: validate_Phone,
          },
        ],
        sms_code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            required: true,
            validator: codeCheck,
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
          {
            required: true,
            validator: codeCheck,
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            pattern: /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/,
            // min: 6,
            // max: 16,
            message: "请输入至少7位以上含字母、数字或特殊符号的组合密码",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
          {
            required: true,
            validator: pwdCheck,
            trigger: "blur",
          },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      schoolid: "",
      schoolinfo: {},
      flag: "",
      baseVal: "",
      isInputType: false, // true: 手机号; false: 邮箱
      get_code: false,
      registerLoad: false,
      submitLoad: false,
      loginText: "",
    };
  },
  computed: {
    ...mapState(["websiteInfo"]),
  },
  watch: {
    websiteInfo: {
      handler(newV, oldV) {
        this.setSiteInfo(newV);
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    this.flag = this.$route.query.flag;
    this.getSchoolList(); // 获取学校列表
    // window.addEventListener('storage', this.afterQRScan)
    this.schoolid = localStorage.getItem("schoolid") * 1 || 1;
    // console.log("login sid", this.schoolid);
    let info = localStorage.getItem("schoolinfo");
    if (info) {
      this.schoolinfo = JSON.parse(info);
    } else {
      getschoolinfo({}).then((res) => {
        if (res && res.code === 0) {
          this.schoolinfo = res.data;
        }
        // console.log("根据学校id获取到的信息", this.schoolinfo);
      });
    }
  },
  mounted() {
    // if (this.loginForm.mobile === "") {
    //   this.$refs.username.focus();
    // } else if (this.loginForm.password === "") {
    //   this.$refs.password.focus();
    // }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    // 获取学校列表
    getSchoolList() {
      getschoollist(this.schoolparames)
        .then((res) => {
          if (res.code === 0 && res.data) {
            const odata = res.data.data || [];
            this.schoolMoreItems = odata.length > 9 ? true : false;
            this.schooloption = this.schooloption.concat(odata);
          }
        })
        .catch((error) => {});
    },
    selectChange(key) {
      this.loading = true;
      this.schoolparames = {
        page: 1,
        keyword: key,
      };
      getschoollist(this.schoolparames)
        .then((res) => {
          if (res.code === 0 && res.data) {
            this.loading = false;
            const odata = res.data.data || [];
            this.schoolMoreItems = odata.length > 9 ? true : false;
            this.schooloption = odata;
          }
        })
        .catch((error) => {});
    },
    // 下拉加载
    handleSchoolScroll() {
      if (this.schoolMoreItems) {
        // 增加当前页码
        this.schoolparames.page++;
        // 调用分页接口，传递搜索关键字和分页参数
        this.getSchoolList();
      }
    },
    getCode() {
      this.get_code = true;
      let data = this.isInputType ? "mobile" : "email";
      if (data === "email") {
        // 邮箱验证码
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (regEmail.test(this.loginForm.email)) {
          const time = new Date().getTime();
          const params = {
            email: this.loginForm.email,
            sign: md5(md5(this.loginForm.email) + time),
            time: time,
          };
          sendemailcode(params)
            .then((response) => {
              if (response.code === 0) {
                this.get_code = false;
                this.$message.success("验证码发送成功!");
                const TIME_COUNT = 60;
                if (!this.timer) {
                  this.count = TIME_COUNT;
                  this.show = false;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                      this.count--;
                    } else {
                      this.show = true;
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
                this.loginForm.code = response.data;
              } else {
                this.get_code = false;
                this.$message({
                  message: response.message,
                  type: "error",
                });
              }
            })
            .catch((error) => {
              this.get_code = false;
            });
        } else {
          this.get_code = false;
          this.$message({
            message: "请输入正确的邮箱!",
            type: "error",
          });
        }
      } else {
        // 手机验证码
        if (this.loginForm.mobile && this.loginForm.mobile.length === 11) {
          const time = new Date().getTime();
          const params = {
            mobile: this.loginForm.mobile,
            sign: md5(md5(this.loginForm.mobile) + time),
            time: time,
          };
          sendsmscode(params)
            .then((response) => {
              const { data } = response;
              if (response.code === 0) {
                this.get_code = false;
                const TIME_COUNT = 60;
                if (!this.timer) {
                  this.count = TIME_COUNT;
                  this.show = false;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                      this.count--;
                    } else {
                      this.show = true;
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
              } else if (response.data && response.data.sms_code) {
                this.get_code = false;
                this.loginForm.sms_code = response.data.sms_code;
              } else {
                this.get_code = false;
                this.$message({
                  message: response.message,
                  type: "error",
                });
              }
            })
            .catch((error) => {
              this.get_code = false;
            });
        } else {
          this.get_code = false;
          this.$message({
            message: "请输入正确的手机号码!",
            type: "error",
          });
        }
      }
    },
    // 注册
    registerUaserName(formName) {
      this.registerLoad = true;
      const params = {
        // mobile: this.loginForm.mobile,
        email: this.loginForm.email,
        school_id: this.loginForm.school_id,
        password: this.loginForm.password,
        confirm_password: this.loginForm.confirm_password,
        // sms_code: this.loginForm.sms_code,
        code: this.loginForm.code,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          registerUaserName(params)
            .then((response) => {
              if (response.code === 0) {
                this.registerLoad = false;
                this.$message({
                  message: "恭喜您，注册成功！！！",
                  type: "success",
                });
                this.$router.push({ path: "/login" });
              } else {
                this.registerLoad = false;
                this.$message({
                  message: "验证码错误!",
                  type: "error",
                });
                // 错误后验证码置空
                this.loginForm.code = "";
                this.codeCheck();
              }
            })
            .catch((error) => {
              this.registerLoad = false;
            });
        } else {
          this.registerLoad = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    loginName() {
      this.$router.push({
        path: "/login",
      });
    },
    submit(formName) {
      this.submitLoad = true;
      let params = {
        password: this.loginForm.password,
        confirm_password: this.loginForm.confirm_password,
        code: this.loginForm.code,
      };
      if (this.isInputType) {
        params.mobile = this.loginForm.mobile;
      } else {
        params.email = this.loginForm.email;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          forgetpw(params)
            .then((response) => {
              if (response.code === 0) {
                this.submitLoad = false;
                this.$message({
                  message: "密码重置成功！！！",
                  type: "success",
                });
                this.$router.push({ path: "/login" });
              }
            })
            .catch((error) => {
              this.submitLoad = false;
            });
        } else {
          this.submitLoad = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    inputType() {
      this.show = true;
      if (!/^1[3456789]\d{9}$/.test(this.baseVal)) {
        // 是邮箱
        this.isInputType = false;
        this.loginForm.email = this.baseVal;
        this.loginForm.code = "";
      } else {
        // 是手机号
        this.isInputType = true;
        this.loginForm.mobile = this.baseVal;
        this.loginForm.sms_code = "";
      }
    },
    setSiteInfo(data) {
      this.icon1 = data.portal_head_logo;
      this.loginText = data.portal_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  // border: 1px solid red;
  height: 100%;
  width: 100%;
  position: relative;
  background: url("../../assets/登录/ujoybj.png") 0 0 no-repeat;
  background-size: 100% 100%;
  // overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .centercontain {
    // border: 1px solid red;
    width: 1286px;
    // height: 685px;
    display: flex;
    .left {
      position: relative;
      width: 481px;
      .logocintain {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #ffffff;
        letter-spacing: 0.1em;
        padding: 0px 40px;
        text-align: center;
      }
      .backimage {
        height: 100%;
      }
    }
    .right {
      flex: 1;
      background: #ffffff;
      padding-bottom: 30px;
      .logoitem {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .inputcontain {
          width: 411px;
          height: 60px;
          border: 1px solid #5493fb;
          border-radius: 10px;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .user {
            margin-left: 20px;
            // margin-right: 4px;
          }
          .elinput {
            ::v-deep .el-input__inner {
              border: none;
            }
          }
        }
      }
      .logoitem2 {
        margin-top: 78px;
      }
      .elform {
        .elitem {
          // border: 1px solid red;
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          .inputcontain {
            width: 411px;
            height: 60px;
            border: 1px solid #5493fb;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .user {
              // border: 1px solid red;
              margin-left: 20px;
              // margin-right: 4px;
              width: 27px;
              height: 27px;
            }
            .elinput {
              // border: 1px solid red;
              ::v-deep .el-input__inner {
                border: none;
                height: 60px;
                border-radius: 12px;
              }
            }
            ::v-deep .el-input-group__prepend {
              border: none;
              color: #000;
              height: 60px;
              border-radius: 10px 0 0 10px;
            }
          }
          .inputcontain_1 {
            width: 411px;
            height: 60px;
            border: 1px solid #5493fb;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .user {
              // border: 1px solid red;
              margin-left: 20px;
              // margin-right: 4px;
              width: 27px;
              height: 27px;
            }
            ::v-deep .el-input-group__append {
              border: none;
              color: #000;
              height: 60px;
              border-radius: 0px 10px 10px 0px;
              cursor: pointer;
              width: 60px;
              text-align: center;
            }
            ::v-deep .el-input__inner {
              border: none;
              height: 60px;
              border-radius: 10px 0px 0px 10px;
            }
          }
        }
        .elitem2 {
          margin-top: 31px;
        }
      }
      .passtip {
        margin-top: 10px;
        .contain {
          width: 411px;
          height: 30px;
          // border: 1px solid #5493fb;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding-left: 14px;
          padding-right: 14px;
        }
      }
      .logbt {
        margin-top: 35px;
        .bt {
          width: 200px;
          height: 60px;
          color: white;
          background: #5493fb;
          // border: 1px solid #32a4db;
          border-radius: 10px;
        }
        .login_name {
          margin-left: 50px;
          color: #5493fb;
          cursor: pointer;
        }
      }
    }
  }
  .my_eldialog {
    .dialog_con {
      width: 100%;
      height: 340px;

      .title_t {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: #5493fb;
      }
      .icon_con {
        margin: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        //  border: 1px solid red;
        .item_con {
          margin-right: 20px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .el_icon {
            position: relative;
            width: 80px;
            height: 80px;
            .img_icon {
              position: absolute;
              top: 0px;
            }
          }
          .text_t {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .bt_con {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          border-radius: 20px;
          width: 220px;
          height: 50px;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  box-shadow: 1px 0px 6px 2px #ccc !important;
  border-radius: 10px !important;
}
::v-deep .el-dialog__header {
  padding: 0px;
}
.itemSelect {
  ::v-deep .el-input__inner {
    width: 411px;
    border: none;
    height: 60px;
    line-height: 60px;
    border-radius: 12px;
  }
}
::v-deep .code_text {
  color: #409eff !important;
  padding: 10px;
}
::v-deep .getCode {
  color: #909399;
}
</style>
